body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error-msg {
  direction: ltr;
  display: block;
  color: red;
  margin: 20px auto 0 auto;
  text-align: center;

  &::before {
    font-size: inherit;
    display: inline;
    content: '❌';
    margin-right: 7px;
  }
}

.success-msg {
  display: block;
  color: green;
  margin: 20px auto;
  text-align: center;

  &::before {
    display: inline;
    content: '✅';
    margin-right: 7px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
