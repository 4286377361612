@font-face {
  font-family: 'EngFontBold';
  src        : url('./Assets/Barnet-Sans-Trial/Trial/BarnetSans-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'EngFontHeavy';
  src        : url('./Assets/Barnet-Sans-Trial/Trial/BarnetSans-Heavy.otf') format('truetype');
}

@font-face {
  font-family: 'EngFontMedium';
  src        : url('./Assets/Barnet-Sans-Trial/Trial/BarnetSans-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'EngFontRegular';
  src        : url('./Assets/Barnet-Sans-Trial/Trial/BarnetSans-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'EngFontBlack';
  src        : url('./Assets/Barnet-Sans-Trial/Trial/BarnetSans-Black.otf') format('truetype');
}

@font-face {
  font-family: 'ArFontBold';
  src        : url('./Assets/Font/Cairo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ArFontSemi';
  src        : url('./Assets/Font/Cairo-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'ArFontRegular';
  src        : url('./Assets/Font/Cairo-Regular.ttf') format('truetype');
}

.arregular {
  font-family: 'ArFontRegular';
}

.enregular {
  font-family: 'EngFontRegular';
}

.arbold {
  font-family: 'ArFontBold';
}

.enbold {
  font-family: 'EngFontBold';
}

.armedium {
  font-family: '';
}

.enmedium {
  font-family: 'EngFontMedium';
}

.arsemi {
  font-family: 'ArFontSemi';
}

.enheavy {
  font-family: 'EngFontHeavy';
}

.ar-bgimg {
  background-image   : url('./Assets/bg.svg');
  background-size    : 56rem 52rem;
  background-repeat  : no-repeat;
  background-position: left;
}

.en-bgimg {
  background-image   : url('./Assets/Background.svg');
  background-size    : 53rem 52rem;
  background-repeat  : no-repeat;
  background-position: right;
}

.header-content {
  white-space: pre-line;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.home-link {
  color: #0047BBCC;
}

.other-link {
  color : #5E6A8B;
  cursor: pointer;
}

.header-navigation {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  padding        : 3rem 6rem;
}

.logo img {
  width: 6rem;
}

.links ul {
  list-style: none;
  padding   : 0;
  margin    : 0;
  display   : flex;
}

.links a {
  text-decoration: none;
}

.links ul li {
  font-size: 1.1rem;
  margin   : 1.25rem;
}

.language-switcher,
.login-button {
  color           : #fff;
  background-color: #0047BBCC;
  padding         : 0.3125rem 1.5625rem;
  border-radius   : 0.3125rem;
  border-style    : none;
  cursor          : pointer;
  font-size       : 0.875rem;
}

.login-button-ar {
  padding         : 0.125rem 0.75rem;
  color           : #fff;
  background-color: #0047BBCC;
  border-radius   : 0.3125rem;
  border-style    : none;
  cursor          : pointer;
  font-size       : 0.875rem;
}

.login-button-medium {
  font-size: 1.875rem;
  border-radius: 0.6375rem;
  opacity: 1;
}

.download-button {
  background-color: #0047BBCC;
  color           : #fff;
  box-shadow      : 0px 0.4375rem 0.875rem #9DADFF93;
  border-radius   : 0.3125rem;
  padding         : 0.75rem;
  border-style    : none;
  cursor          : pointer;
  font-size       : 1rem;
}

.btn-play {
  display: flex;
}

.watch {
  font-size  : 1rem;
  margin     : 0;
  display    : flex;
  align-items: center;
  color      : #5E6A8B;
}

.ply-icon {
  position: relative;
  z-index : 1;
}

.ply-icon:before {
  content      : "";
  display      : block;
  position     : absolute;
  border-radius: 50%;
  background   : #F4F5F8;
  width        : 1rem;
  top          : 12%;
  height       : 1rem;
  z-index      : -1;
}

.watchi {
  display: flex;
  cursor : pointer;
}

.header {
  padding-left  : 6rem;
  padding-right : 6rem;
  padding-bottom: 6rem;
}

.header-content {
  display        : flex;
  justify-content: space-around;
  align-items    : center;
}

.header-right {
  display        : flex;
  justify-content: flex-start;
  align-items    : flex-start;
  width          : 29rem;
  height         : 25rem;
}

.header-right-ar {
  display        : flex;
  justify-content: flex-end;
  align-items    : flex-start;
  width          : 29rem;
  height         : 25rem;
}

.blue-welcome {
  color    : #0047BBCC;
  font-size: 3.7rem;
}

.image-mobile {
  width          : 30rem;
  display        : flex;
  justify-content: flex-start;
  align-items    : flex-start;
}

.header-left p {
  line-height: 130%;
}

.enunderline-text {
  position: relative;
}

.underline-textt {
  position: relative;
}

.enrevolutionizing::before {
  content   : "";
  position  : absolute;
  left      : 52%;
  bottom    : 0.4375rem;
  width     : 28.6rem;
  height    : 0.875rem;
  transform : skew(0deg) translateX(-50%);
  background: #E0EAFF;
  z-index   : -1;
}

.enunderline-text::before {
  content   : "";
  position  : absolute;
  left      : 52%;
  bottom    : 0.75rem;
  width     : 43.75rem;
  height    : 0.875rem;
  transform : skew(0deg) translateX(-50%);
  background: #E0EAFF;
  z-index   : -1;
}

.message {
  font-size: 18px;
  border: 2px solid red;
  width: 500px;
  margin: 20px auto;
  padding: 10px;
  text-align: center;
}

.error-message {
  padding: 10px;
  background-color: #e74c3c;
  color: #fff;
  font-size: 20px;
  width: 600px;
  text-align: center;
  position: fixed;
  top: 80px;
  right: -600px;
  z-index: 9999;
}

.arunderline-text {
  position: relative;
}

.arunderline-textt {
  position: relative;
}

.arrevolutionizing::before {
  content   : "";
  position  : absolute;
  left      : 45%;
  bottom    : 1.25rem;
  width     : 15rem;
  height    : 0.875rem;
  transform : skew(0deg) translateX(-50%);
  background: #E0EAFF;
  z-index   : -1;
}

.arunderline-text::before {
  content   : "";
  position  : absolute;
  left      : 50%;
  bottom    : 2.4rem;
  width     : 24rem;
  height    : 0.875rem;
  transform : skew(0deg) translateX(-50%);
  background: #E0EAFF;
  z-index   : -1;
}

.button {
  padding         : 0.625rem 1.25rem;
  background-color: #007bff;
  color           : #fff;
  border          : none;
  border-radius   : 0.3125rem;
  cursor          : pointer;
  margin-right    : 0.625rem;
}

.button:hover {
  background-color: #0056b3;
}

.social-icons {
  display        : flex;
  align-items    : center;
  margin-top     : 1.8rem;
  width          : 22%;
  justify-content: space-between;
  list-style     : none;
  padding        : 0;
  z-index        : 1;
}

.arsi {
  display           : flex;
  align-items       : center;
  margin-top        : 1.8rem;
  /* width          : 96%; */
  width             : 39%;
  justify-content   : space-between;
  list-style        : none;
  padding           : 0;
  z-index           : 1;
}

.asocial-icons {
  z-index   : 1;
  margin-top: 0.425rem;
}

.arsocial-icons {
  display        : flex;
  align-items    : center;
  margin-top     : 0;
  justify-content: space-between;
  width          : 100%;
  list-style     : none;
  padding        : 0;
  z-index        : 1;
}

.social-icons .social-icon {
  position: relative;
  width   : 0.9375rem;
  height  : 0.9375rem;
  margin  : 0 1rem;
  padding : 0;
}

.social-icons .insta::before {
  content         : "";
  position        : absolute;
  left            : 55%;
  top             : 55%;
  transform       : translate(-50%, -50%);
  width           : 150%;
  height          : 150%;
  border          : 0px solid #5E6A8B;
  border-radius   : 50%;
  padding         : 0.4375rem;
  background-color: #0047BBCC;
  z-index         : -1;
}

.arsi .insta::before {
  content         : "";
  position        : absolute;
  left            : 42%;
  top             : 55%;
  transform       : translate(-50%, -50%);
  width           : 150%;
  height          : 150%;
  border          : 0px solid #5E6A8B;
  border-radius   : 50%;
  padding         : 0.4375rem;
  background-color: #0047BBCC;
  z-index         : -1;
}

.social-icons .twitter::before,
.social-icons .linked::before {
  content      : "";
  position     : absolute;
  left         : 55%;
  top          : 55%;
  transform    : translate(-50%, -50%);
  width        : 150%;
  height       : 150%;
  border       : 1px solid #5E6A8B;
  border-radius: 50%;
  padding      : 0.4375rem;
  z-index      : -1;
}

.arsi .twitter::before,
.arsi .linked::before {
  content      : "";
  position     : absolute;
  left         : 42%;
  top          : 55%;
  transform    : translate(-50%, -50%);
  width        : 150%;
  height       : 150%;
  border       : 1px solid #5E6A8B;
  border-radius: 50%;
  padding      : 0.4375rem;
  z-index      : -1;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1080px) {
  .image-mobile {
    width: 27rem;
  }

  .blue-welcome {
    color     : #0047BBCC;
    font-size : 3.75rem;
    text-align: center;
  }

  .mobile-links ul {
    padding        : 0;
    list-style     : none;
    text-decoration: none;
    margin         : 0.625rem 0;
    padding-bottom : 1.25rem;
    padding-top    : 1.25rem;
  }

  .menu-open {
    background-color: white;
    text-align      : center;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
  }

  .header-top {
    flex-direction : row;
    display        : flex;
    justify-content: space-between !important;
    padding-top    : 3rem;
    padding-bottom : 0rem;
    padding-left   : 6rem;
    padding-right  : 6rem;
  }

  .mobile-menu {
    display       : flex;
    flex-direction: column;
    align-items   : center;
  }

  .header-navigation {
    display: block;
    padding: 0rem 0rem;
  }

  .blue-bg {
    background-color: #E5ECF8;
    color           : #0047BBCC;
  }

  .other-link {
    color: #5E6A8BB3;
  }

  .mobile-links ul li {
    margin : 0.1875rem 0;
    padding: 0.3125rem;
  }

  .header {
    padding: 0;
  }

  .header-content {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    flex-direction : column-reverse;
  }

  .ply-icon:before {
    content      : "";
    display      : block;
    position     : absolute;
    border-radius: 50%;
    background   : #FFFFFF;
    width        : 0.9375;
    top          : 5%;
    height       : 0.9375;
    z-index      : -1;
  }

  .header-left {
    display: contents;
  }

  .underline {
    order  : 4;
    z-index: 1;
  }

  .header-right {
    order          : 3;
    padding-bottom : 2rem;
    justify-content: center;
    height         : 27rem;
  }

  .header-right-ar {
    order          : 3;
    padding-bottom : 2rem;
    justify-content: center;
    height         : 27rem;
  }

  .header-right img {
    padding-top: 1.5rem;
  }

  .header-right-ar img {
    padding-top: 1.5rem;
  }

  .btn-play {
    order: 2;
  }

  .btn-play-en {
    padding-bottom: 1rem;
  }

  .btn-play-ar {
    padding-bottom: 1rem;
  }

  .social-icons {
    order: 1;
  }

  .reverse {
    display       : flex;
    flex-direction: column-reverse;
  }

  .en-bgimg {
    background-image   : url('./Assets/Background.svg');
    background-size    : 79rem 66rem;
    background-repeat  : no-repeat;
    background-position: right;
  }

  .ar-bgimg {
    background-image   : url('./Assets/bg.svg');
    background-size    : 79rem 66rem;
    background-repeat  : no-repeat;
    background-position: left;
  }

  .armob-semi {
    font-family: 'ArFontSemi';
  }

  .enmob-med {
    font-family: 'EngFontMedium';
  }

  .enmob-reg {
    font-family: 'EngFontRegular';
  }

  .arsocial-icons {
    margin-top     : 0;
    justify-content: space-evenly;
    width          : 16%;
  }
}


@media only screen and (max-width: 855px) {
  .en-bgimg {
    background-image   : url('./Assets/Background.svg');
    background-size    : 70rem 52rem;
    background-repeat  : no-repeat;
    background-position: top;
  }

  .ar-bgimg {
    background-image   : url('./Assets/bg.svg');
    background-size    : 70rem 52rem;
    background-repeat  : no-repeat;
    background-position: top;
  }

  .blue-welcome {
    color     : #0047BBCC;
    font-size : 2rem;
    text-align: center;
    z-index   : 2;
  }

  .header-top {
    padding-left  : 2rem;
    padding-right : 2rem;
    padding-bottom: 2rem;
  }

  .enrevolutionizing::before {
    content   : "";
    position  : absolute;
    left      : 52%;
    bottom    : -0.125rem;
    width     : 15.3125rem;
    height    : 0.625rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .enunderline-text::before {
    content   : "";
    position  : absolute;
    left      : 52%;
    bottom    : 0.0625rem;
    width     : 22.8125rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .underline {
    z-index: 1;
  }

  .btn-play-en {
    align-self    : flex-start;
    padding-left  : 1.5rem;
    padding-bottom: 1rem;
  }

  .btn-play-ar {
    align-self    : flex-start;
    padding-right : 1.5rem;
    padding-bottom: 1rem;
  }

  .social-icons {
    align-self: flex-start;
  }

  .si {
    padding-left   : 1.2rem;
    justify-content: space-between;
    width          : 39%;

  }

  .arsi {
    padding-right: 0rem;
    width        : 64%;
  }

  .asocial-icons {
    display   : flex;
    align-self: flex-start;
    z-index   : 1;
    width     : 46%;
    margin-top: 0;
  }

  .header-right {
    width         : 1.5rem;
    padding-bottom: 0;
  }

  .header-right img {
    height     : 21.875rem;
    padding-top: 1.5rem;
  }

  .header-right-ar {
    width         : 1.5rem;
    padding-bottom: 0;
  }

  .header-right-ar img {
    height     : 21.875rem;
    padding-top: 1.5rem;
  }

  .ply-icon {
    z-index: 1;
  }

  .ply-icon:before {
    background: #FFFFFF;
  }

  .arrevolutionizing::before {
    content   : "";
    position  : absolute;
    left      : 45%;
    bottom    : 0.375rem;
    width     : 8.5rem;
    height    : 0.6875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .arunderline-text::before {
    content   : "";
    position  : absolute;
    left      : 50%;
    bottom    : 0.625rem;
    width     : 12.875rem;
    height    : 0.75rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .arsocial-icons {
    align-self     : flex-start;
    padding-right  : 1.2rem;
    width          : 27%;
    justify-content: space-between;
  }

  .watch {
    font-size: 0.85rem;
  }

  .download-button {
    font-size: 0.9rem;
  }

}

@media only screen and (max-width: 450px) {
  .arsocial-icons {
    align-self   : flex-start;
    padding-right: 1.2rem;
    width        : 41%;
  }

  .si {
    padding-left   : 1.2rem;
    justify-content: space-between;
    width          : 74%;

  }

  .arsi {
    padding-right: 0rem;
    width        : 86%;
  }

}

@media only screen and (min-width:1081px) and (max-width:1120px) {
  .image-mobile {
    width: 27rem;
  }

  .download-button {
    font-size: 0.9rem;
  }

  .watch {
    font-size: 0.9rem;
  }

  .blue-welcome {
    font-size: 1.9375rem;
  }

  .arsocial-icons {
    width: 46%;
  }

  .enrevolutionizing::before {
    content   : "";
    position  : absolute;
    left      : 52%;
    bottom    : 0.25rem;
    width     : 14.6875rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .enunderline-text::before {
    content   : "";
    position  : absolute;
    left      : 52%;
    bottom    : 0.5rem;
    width     : 22.5rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .social-icons {
    width: 37%;
  }

  .arrevolutionizing::before {
    content   : "";
    position  : absolute;
    left      : 45%;
    bottom    : 0.5rem;
    width     : 8.125rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .arunderline-text::before {
    content   : "";
    position  : absolute;
    left      : 50%;
    bottom    : 0.9375rem;
    width     : 12.5rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }
}

@media only screen and (min-width:1121px) and (max-width:1345px) {
  .image-mobile {
    width: 27rem;
  }

  .download-button {
    font-size: 0.95rem;
  }

  .watch {
    font-size: 0.95rem;
  }

  .blue-welcome {
    font-size: 2.5rem;
  }

  .enrevolutionizing::before {
    content   : "";
    position  : absolute;
    left      : 52%;
    bottom    : 0.4375rem;
    width     : 19.125rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .enunderline-text::before {
    content   : "";
    position  : absolute;
    left      : 52%;
    bottom    : 0.625rem;
    width     : 28.75rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .social-icons {
    display        : flex;
    align-items    : center;
    margin-top     : 1.8rem;
    width          : 22%;
    justify-content: space-between;
    list-style     : none;
    padding        : 0;
    z-index        : 1;
  }

  .arrevolutionizing::before {
    content   : "";
    position  : absolute;
    left      : 45%;
    bottom    : 0.875rem;
    width     : 10.625rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .arunderline-text::before {
    content   : "";
    position  : absolute;
    left      : 50%;
    bottom    : 1.375rem;
    width     : 16.25rem;
    height    : 0.875rem;
    transform : skew(0deg) translateX(-50%);
    background: #E0EAFF;
    z-index   : -1;
  }

  .arsocial-icons {
    display        : flex;
    align-items    : center;
    margin-top     : 0;
    justify-content: space-between;
    width          : 40%;
    list-style     : none;
    padding        : 0;
    z-index        : 1;
  }

  .arsi {
    display        : flex;
    align-items    : center;
    margin-top     : 1.8rem;
    width          : 96%;
    justify-content: space-between;
    list-style     : none;
    padding        : 0;
    z-index        : 1;
  }
}

/* Boxes */

.box-section {
  padding         : 3rem 6rem;
  background-color: #F4F5F8;
}

.box-header {
  padding-bottom: 2rem;
  margin        : 0 auto;
  width         : 90%;
}

.ar-box-header {
  padding-bottom: 2rem;
  margin        : 0 auto;
  width         : 90%;
}

.box-header h1 {
  color     : #2A2A2A;
  font-size : 2.11rem;
  margin-top: 0;
}

.box-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.3125rem;
}

.ar-box-header h1 {
  color     : #2A2A2A;
  font-size : 2.11rem;
  margin-top: 0;
}

.ar-box-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.9125rem;
}

.box {
  display      : flex;
  background   : #FCFCFC 0% 0% no-repeat padding-box;
  padding      : 0.3125rem 1.25rem;
  border-radius: 0.5rem;
  width        : 90%;
  white-space  : nowrap;
  gap          : 1rem;
}

.box img {
  display   : flex;
  align-self: center;
}

.box-container {
  display        : flex;
  flex-direction : row;
  gap            : 1rem;
  justify-content: space-between;
  margin         : 0 auto;
  width          : 90%;
}

.box-content {
  gap            : 0.5rem;
  width: 250px;
}

.box-content h2 {
  color        : #2A2A2A;
  font-size    : 1.35rem;
  margin-bottom: 1rem;
}

.ar-box-content h2 {
  color    : #2A2A2A;
  font-size: 1.35rem;
}

.box-content p {
  color     : #5E6A8B;
  margin-top: -0.625rem;
}

.ar-box-content p {
  color     : #5E6A8B;
  margin-top: -1.25rem;
  font-size : 1.1rem;
}

.ar-box-content {
  margin-right: 1rem;
}


@media screen and (min-width: 1240px) and (max-width: 1380px) {
  .box-header h1 {
    font-size : 2.01rem;
    margin-top: 0;
  }

  .box-header p {
    font-size: 0.83rem;
  }

  .ar-box-header h1 {
    font-size : 2.01rem;
    margin-top: 0;
  }

  .ar-box-header p {
    font-size: 0.83rem;
  }

  .box-content h2 {
    font-size: 1.15rem;
  }

  .ar-box-content h2 {
    font-size: 1.15rem;
  }

  .box-content p {
    font-size: 0.9rem;
  }

  .ar-box-content p {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1240px) {
  .box-header h1 {
    font-size: 1.91rem;
  }

  .box-header p {
    font-size: 0.73rem;
  }

  .ar-box-header h1 {
    font-size: 1.91rem;
  }

  .ar-box-header p {
    font-size: 0.73rem;
  }

  .box-content h2 {
    font-size: 1.15rem;
  }

  .ar-box-content h2 {
    font-size: 1.15rem;
  }

  .box-content p {
    font-size: 0.77rem;
  }

  .ar-box-content p {
    font-size: 0.77rem;
  }
}


@media screen and (min-width: 1500px) {
  .box-container {
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
  }

  .box {
    width          : 26%;
    align-items    : center;
    align-self     : center;
  }
}

@media screen and (max-width: 1100px) {
  .box-section {
    flex-direction: column;
    padding       : 3rem;
  }

  .box-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .box {
    align-items    : center;
    justify-content: center;
  }

  .box-header {
    text-align: center;
  }
}

@media only screen and (max-width: 855px) {
  .box-header {
    text-align: center;
  }

  .box-section {
    flex-direction: column;
    padding       : 2rem;
  }

  .ar-box-header {
    text-align: center;
  }

  .box-header h1 {
    font-size : 1.4rem;
    margin-top: 0;
  }

  .box-header p {
    font-size: 0.75rem;
  }

  .ar-box-header h1 {
    font-size : 1.4rem;
    margin-top: 0;
  }

  .ar-box-header p {
    margin-top: 0.9rem;
    font-size : 0.73rem;
  }

  .box-content h2 {
    font-size: 1.15rem;
  }

  .ar-box-content h2 {
    font-size: 1.15rem;
  }
}

/* Features */

.feature-center {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  width         : 90%;
  padding       : 3rem 0;
}

.features-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-wrap      : wrap;
  width          : 90%;
  margin         : 0 auto;
  white-space    : pre-line;
}

.ar-features-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-wrap      : wrap;
  width          : 90%;
  margin         : 0 auto;
  white-space    : pre-line;
}

.feature {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  width          : 100%;
}

.content3 {}

.feature3 {
  padding-left: 1rem;
}

.feat-between {
  justify-content: space-around;
  width          : 100%;
}

.feat-around {}

.feature.reverse {
  flex-direction : row-reverse;
  justify-content: space-around;
}

.feature-content {
  padding: 0.625rem;
  margin : 0;
}

.feature-content h1 {
  font-size: 2.11rem;
  color    : #363636;
}

.feature-content ul {
  list-style: none;
  padding   : 0;
}

.feature-content ul li {
  margin-bottom: 0.5rem;
  color        : #5E6A8B;
  font-size    : 1.1rem;
  line-height  : 1.4rem;
}

.lineheight3 {
  line-height: 1.8rem !important;
}

.feature-image {
  max-width: 100%;
  height   : auto;
}

.feature-line {
  width           : 4.06rem;
  margin          : 1.875rem 0 0 0;
  height          : 0.2rem;
  background-color: #0047BBCC;
  border-radius   : 1rem;
}

.center-image {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.center-imag {
  display    : flex;
  align-items: center;
}

@media (min-width: 1240px) and (max-width:1330px) {
  .features-container {}

  .ar-center-image {
    /* width: 42%; */
  }
}

@media (max-width: 1240px) {
  .features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    white-space    : pre-line;
  }

  .ar-center-image {
    width: auto !important;
  }

  .ar-features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    white-space    : pre-line;
  }

  .feature-content h1 {
    font-size: 1.4rem;
  }

  .feature-content ul li {
    font-size: 0.93rem;
  }

  ;

  .feat-around {
    margin-right: 0px !important;
    margin-left : 0px;
  }

}

@media (max-width: 1200px) {
  .feature {
    flex-direction: column;
  }

  .feature.reverse {
    flex-direction: column;
  }

  .feature-image {
    order: 1;
  }

  .feature-content h1 {
    font-size : 1.4rem;
    text-align: center;
  }

  .feat-around {
    margin: 0;
  }

  .center-imag {
    width          : 53.4%;
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  .enmob-medium {
    font-family: "EngFontMedium";
  }

  .feature-content ul li {
    font-size: 0.93rem;

  }

  .features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    gap            : 2.5rem;
    margin         : 1.25rem;
    padding        : 3rem 0rem;
    white-space    : pre-line;
  }

  .ar-features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    gap            : 2.5rem;
    margin         : 1.25rem;
    padding        : 3rem 0rem;
    white-space    : pre-line;
  }

  .feature-content {
    width: auto;
  }

  .feature-line {
    margin: auto;
    width : 3.4rem;
  }

}

@media (max-width: 850px) {
  .feature {
    flex-direction: column;
  }

  .feature.reverse {
    flex-direction: column;
  }

  .feature-image {
    order: 1;
  }

  .feature-content h1 {
    font-size : 1.1rem;
    text-align: center;
  }

  .feat-around {
    margin: 0;
  }

  .enmob-medium {
    font-family: "EngFontMedium";
  }

  .feature-content ul li {
    font-size: 0.73rem;

  }

  .features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    gap            : 2.5rem;
    margin         : 1.25rem;
    padding        : 3rem 0rem;
    white-space    : pre-line;
  }

  .ar-features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    gap            : 2.5rem;
    margin         : 1.25rem;
    padding        : 3rem 0rem;
    white-space    : pre-line;
  }

  .feature-content {
    width: auto;
  }

  .feature-line {
    margin: auto;
    width : 3.4rem;
  }
}

@media screen and (min-width:1700px) {
  .features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    margin         : 0 auto;
    white-space    : pre-line;

  }

  .ar-features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    margin         : 0 auto;
    white-space    : pre-line;
  }


}

@media screen and (min-width:2000px) {
  .features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    margin         : 0 auto;
    white-space    : pre-line;
  }

  .ar-features-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    margin         : 0 auto;
    white-space    : pre-line;
  }

}

.ar-center-image {
  /* width: 42%; */
}


/* PickUp */

.pickup-section {
  padding         : 3rem 6rem;
  background-color: #F4F5F8;
  white-space     : pre-line;
}

.pickup-center {
  margin: 0 auto;
  width : 90%;
}

.ar-pickup-header {
  padding-bottom: 1.5rem;
}

.pickup-header h1 {
  color        : #2A2A2A;
  font-size    : 2.11rem;
  margin-top   : 0;
  margin-bottom: 1rem;
}

.pickup-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.3125rem;
}

.ar-pickup-header h1 {
  color        : #2A2A2A;
  font-size    : 2.11rem;
  margin-top   : 0;
  margin-bottom: 1rem;
}

.ar-pickup-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.9125rem;
}

.pickup {
  display      : flex;
  background   : #FCFCFC 0% 0% no-repeat padding-box;
  padding      : 0.3125rem 1.25rem;
  border-radius: 0.5rem;
  width        : 100%;
}

.pickup img {
  display   : flex;
  align-self: center;
}

.pickup-container {
  display        : flex;
  flex-direction : row;
  width          : 90%;
  gap            : 2.25rem;
  justify-content: space-around;
  align-items    : center;
}

.pickup-content {
  margin-left: 1rem;
}

.pickup-content p {
  line-height: 150%;
}

.ar-pickup-content p {
  line-height: 150%;
}

.pickup-content h2 {
  color        : #0047BBCC;
  font-size    : 3.35rem;
  margin-bottom: 1.5rem;
  position     : relative;
  margin-top   : 1.5rem;
  margin-left  : 0rem;
  z-index      : 1;
}

.pickup-content h2::before {
  content   : "";
  position  : absolute;
  top       : 2.35rem;
  left      : 0.5rem;
  width     : 2rem;
  height    : 0.875rem;
  background: #0047BB1A 0% 0% no-repeat padding-box;
  z-index   : -1;
}

.ar-pickup-content h2 {
  color        : #0047BBCC;
  font-size    : 3.35rem;
  position     : relative;
  margin-bottom: 2rem;
  margin-top   : 0rem;
  margin-left  : 0rem;
  z-index      : 1;
}

.ar-pickup-content h2::before {
  content   : "";
  position  : absolute;
  top       : 2.35rem;
  width     : 2rem;
  height    : 0.875rem;
  background: #0047BB1A 0% 0% no-repeat padding-box;
  z-index   : -1;
}

.pickup-content p {
  color     : #5E6A8B;
  margin-top: -0.625rem;
  font-size : 1.1rem;
}

.ar-pickup-content p {
  color     : #5E6A8B;
  margin-top: -1.25rem;
  font-size : 1.1rem;
}

.ar-pickup-content {
  margin-right: 1rem;
}

.mobile-hr {
  display: none;
}

@media screen and (min-width: 1101px) and (max-width: 1270px) {
  .pickup-header h1 {
    font-size: 2rem;
  }

  .pickup-header p {
    font-size: 0.83rem;
  }

  .ar-pickup-header h1 {
    font-size: 2rem;
  }

  .ar-pickup-header p {
    font-size: 0.83rem;
  }

  .pickup-content h2 {
    font-size: 3rem;
  }

  .ar-pickup-content h2 {
    font-size: 3rem;
  }

  .pickup-content p {
    font-size: 0.9rem;
  }

  .ar-pickup-content p {
    font-size: 0.9rem;
  }

  .pickup-content h2::before {
    content   : "";
    position  : absolute;
    top       : 2rem;
    left      : 0.4rem;
    width     : 2rem;
    height    : 0.875rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .ar-pickup-content h2::before {
    content   : "";
    position  : absolute;
    top       : 2rem;
    width     : 2rem;
    height    : 0.875rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }
}

@media screen and (min-width: 1500px) {
  .pickup {
    width          : fit-content;
    align-items    : center;
    align-self     : center;
    justify-content: center;
  }
}

@media screen and (max-width: 1100px) {
  .pickup-section {
    flex-direction: column;
    padding       : 3rem;
  }

  .pickup-container {
    flex-direction: column;
    width         : 100%;
  }

  .pickup {
    width          : fit-content;
    align-items    : center;
    align-self     : center;
    justify-content: center;
  }

  .pickup-header {
    text-align: center;
  }

  .ar-pickup-header {
    text-align: center;
  }

  .pickup-header h1 {
    font-size : 2rem;
    margin-top: 0;
  }

  .pickup-header p {
    font-size: 0.83rem;
  }

  .ar-pickup-header h1 {
    font-size    : 1.8rem;
    margin-top   : 0;
    margin-bottom: 1.7rem;

  }

  .ar-pickup-content p {
    line-height: 1.7rem;
  }

  .ar-pickup-header p {
    font-size: 0.83rem;
  }

  .pickup-content h2 {
    font-size : 3rem;
    text-align: center;
  }

  .ar-pickup-content h2 {
    font-size : 3rem;
    text-align: center;
  }

  .pickup-content p {
    font-size : 0.9rem;
    text-align: center;
  }

  .ar-pickup-content p {
    font-size : 0.9rem;
    text-align: center;
  }

  .pickup-content h2::before {
    content   : "";
    position  : absolute;
    top       : 2rem;
    left      : 5.3rem;
    width     : 2rem;
    height    : 0.875rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .ar-pickup-content h2::before {
    content   : "";
    position  : absolute;
    top       : 2rem;
    left      : 5.3rem;
    width     : 2rem;
    height    : 0.875rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .pickup-content .en-nb1::before {
    content   : "";
    position  : absolute;
    top       : 2rem;
    left      : 6.5rem;
    width     : 2rem;
    height    : 0.875rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .ar-pickup-content .ar-nb3::before {
    content   : "";
    position  : absolute;
    top       : 1.9rem;
    left      : 6rem;
    width     : 1.5rem;
    height    : 0.6rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .container-hr {
    text-align: center;
  }

  .mobile-hr {
    display         : block;
    width           : 3.3rem;
    margin          : 1.2rem auto 0 auto;
    height          : 0.15rem;
    background-color: #0047BBCC;
    border-radius   : 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .pickup-header {
    text-align: center;
  }

  .pickup-section {
    flex-direction: column;
    padding       : 2rem;
  }

  .ar-pickup-header {
    text-align: center;
  }

  .pickup-header h1 {
    font-size : 1.4rem;
    margin-top: 0;
  }

  .pickup-header p {
    font-size: 0.75rem;
  }

  .ar-pickup-header h1 {
    font-size : 1.4rem;
    margin-top: 0;
  }

  .ar-pickup-header p {
    margin-top: 0.9rem;
    font-size : 0.73rem;
  }

  .pickup-content h2 {
    font-size: 1.7rem;
  }

  .ar-pickup-content h2 {
    font-size: 1.7rem;
  }

  .pickup-content p {
    font-size: 0.77rem;
  }

  .ar-pickup-content p {
    font-size: 0.77rem;
  }

  .pickup-content h2::before {
    content   : "";
    position  : absolute;
    top       : 0.9rem;
    left      : 4.8rem;
    width     : 1.5rem;
    height    : 0.6rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .ar-pickup-content h2::before {
    content   : "";
    position  : absolute;
    top       : 0.9rem;
    left      : 4.6rem;
    width     : 1.5rem;
    height    : 0.6rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .pickup-content .en-nb1::before {
    content   : "";
    position  : absolute;
    top       : 0.9rem;
    left      : 5.5rem;
    width     : 1.5rem;
    height    : 0.6rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .ar-pickup-content .ar-nb3::before {
    content   : "";
    position  : absolute;
    top       : 0.9rem;
    left      : 5.2rem;
    width     : 1.5rem;
    height    : 0.6rem;
    background: #0047BB1A 0% 0% no-repeat padding-box;
    z-index   : -1;
  }

  .container-hr {
    text-align: center;
  }

  .mobile-hr {
    display         : block;
    width           : 3.3rem;
    margin          : 1.2rem auto 0 auto;
    height          : 0.15rem;
    background-color: #0047BBCC;
    border-radius   : 1rem;
  }

  .ar-pickup-header h1 {
    margin-bottom: 0;
  }
}




/* Partner */
.partner-container::-webkit-scrollbar {
  width: 0.5em;
}

.partner-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.ar-partner-container::-webkit-scrollbar {
  width: 0.5em;
}

.ar-partner-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.change {
  display        : flex;
  justify-content: center;
}

.partner-section {
  padding         : 3rem 6rem;
  background-color: #fff;
  white-space     : pre-line;
}

.ar-partner-header {
  padding-bottom: 1.5rem;
  margin        : 0 auto;
  width         : 90%;
}

.partner-header {
  margin: 0 auto;
  width : 90%;
}

.partner-header h1 {
  color        : #2A2A2A;
  font-size    : 2.11rem;
  margin-top   : 0;
  margin-bottom: 1rem;
}

.partner-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.3125rem;
}

.ar-partner-header h1 {
  color        : #2A2A2A;
  font-size    : 2.11rem;
  margin-top   : 0;
  margin-bottom: 1rem;
}

.ar-partner-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.9125rem;
}

.partner {
  display      : flex;
  background   : #FCFCFC 0% 0% no-repeat padding-box;
  padding      : 0.3125rem 1.25rem;
  border-radius: 0.5rem;
  width        : 100%;
}

.partner-container {
  display    : flex;
  overflow-x : auto;
  padding-top: 2rem;
}

.ar-partner-container {
  display        : flex;
  overflow-x     : auto;
  justify-content: flex-end;
}

.partner-image {
  flex        : 0 0 auto;
  margin-right: 15px;
  background  : #FFFFFF00 0% 0% no-repeat padding-box;
  border      : 4px solid #0047BB1A;
  padding     : 0.2rem 2.3rem;
}

.ar-partner-image {
  flex       : 0 0 auto;
  margin-left: 15px;
  background : #FFFFFF00 0% 0% no-repeat padding-box;
  border     : 4px solid #0047BB1A;
  padding    : 0.2rem 2.3rem;
}

.partner-image img {
  width : 100px;
  height: 100px;

}

.ar-partner-image img {
  width : 100px;
  height: 100px;

}

@media (max-width: 1360px) {
  .ar-partner-container {
    display        : flex;
    overflow-x     : auto;
    justify-content: flex-start;
  }

}

@media (max-width: 800px) {

  .partner-section {
    padding: 3rem 0rem 3rem 1rem;
  }

  .partner-image {
    flex        : 0 0 auto;
    margin-right: 10px;
    background  : #FFFFFF00 0% 0% no-repeat padding-box;
    border      : 4px solid #0047BB1A;
    padding     : 0.3rem 1rem;
  }

  .partner-image img {
    width : 50px;
    height: 50px;

  }

  .ar-partner-container {
    display        : flex;
    overflow-x     : auto;
    justify-content: flex-start;
  }

  .ar-partner-image {
    flex        : 0 0 auto;
    margin-right: 0px;
    background  : #FFFFFF00 0% 0% no-repeat padding-box;
    border      : 4px solid #0047BB1A;
    padding     : 0.3rem 1rem;
  }

  .ar-partner-image img {
    width : 50px;
    height: 50px;

  }

  .partner-header h1 {
    font-size    : 1.95rem;
    margin-bottom: 2rem;
    text-align   : center;
  }

  .partner-header p {
    font-size : 0.93rem;
    text-align: center;
  }

  .ar-partner-header h1 {
    font-size    : 1.95rem;
    margin-bottom: 2rem;
    text-align   : center;
  }

  .ar-partner-header p {
    font-size : 0.93rem;
    text-align: center;
  }
}

/* FAQs */

.faq-section {
  padding         : 3rem 6rem;
  background-color: #F4F5F8;
}

.faq-header {
  padding-bottom: 2rem;
  text-align    : center;
}

.ar-faq-header {
  padding-bottom: 2rem;
}

.faq-header h1 {
  color     : #2A2A2A;
  font-size : 2.11rem;
  margin-top: 0;
}

.faq-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.3125rem;
}

.ar-faq-header h1 {
  color     : #2A2A2A;
  font-size : 2.11rem;
  margin-top: 0;
}

.ar-faq-header p {
  color     : #5E6A8B;
  font-size : 0.93rem;
  margin-top: -0.9125rem;
}

@media screen and (min-width: 1185px) and (max-width: 1320px) {
  .faq-header h1 {
    font-size : 2.01rem;
    margin-top: 0;
  }

  .faq-header p {
    font-size: 0.83rem;
  }

  .ar-faq-header h1 {
    font-size : 2.01rem;
    margin-top: 0;
  }

  .ar-faq-header p {
    font-size: 0.83rem;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1185px) {
  .faq-header h1 {
    font-size: 1.91rem;
  }

  .faq-header p {
    font-size: 0.73rem;
  }

  .ar-faq-header h1 {
    font-size: 1.91rem;
  }

  .ar-faq-header p {
    font-size: 0.73rem;
  }
}

@media only screen and (max-width: 855px) {
  .faq-header {
    text-align: center;
  }

  .ar-faq-header {
    text-align: center;
  }

  .faq-header h1 {
    font-size : 1.4rem;
    margin-top: 0;
  }

  .faq-header p {
    font-size: 0.75rem;
  }

  .ar-faq-header h1 {
    font-size : 1.4rem;
    margin-top: 0;
  }

  .ar-faq-header p {
    margin-top: 0.9rem;
    font-size : 0.73rem;
  }
}

.faq-questions {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.faq-question {
  background   : #FFFFFF;
  box-shadow   : 10px 10px 99px #D3D3D329;
  margin-bottom: 25px;
  border-radius: 14px;
  overflow     : hidden;
  transition   : max-height 0.3s ease-in-out;
  width        : 55%;
}

.faq-question-title {
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  padding         : 20px;
  cursor          : pointer;
  color           : #2A2A2A;
  background-color: #FFFFFF;
  user-select     : none;
  font-size       : 1.1rem;
}

.faq-question-title.open {
  background-color: #FFFFFF;
  padding-bottom  : 6px;
}

.arrow-icon {
  width       : 2px;
  height      : 2px;
  border      : solid #5E6A8B;
  border-width: 0 3px 3px 0;
  display     : inline-block;
  padding     : 3px;
  mask-size   : 100% 100%;
  mask-repeat : no-repeat;
  transition  : transform 0.3s ease-in-out;
  margin-left : 20px;
}

.arrow-icon.up {
  transform: rotate(220deg);
}

.arrow-icon.down {
  transform: rotate(45deg);
}

.faq-answer {
  padding  : 0 20px 20px 20px;
  width    : 87%;
  color    : #5E6A8B;
  font-size: 0.9rem;
}

.faq-bottom {
  text-align: center;
  margin-top: 20px;
}

.faq-bottom h2 {
  color    : #2A2A2A;
  font-size: 1.35rem;
}

.faq-bottom .bottom {
  margin-bottom: 0.2rem;
}

.faq-bottom p {
  margin   : 0;
  color    : #5E6A8B;
  font-size: 1rem;
}

.faq-bottom a {
  color          : #5E6A8B;
  text-decoration: underline;
}

.ar-arrow-icon {
  margin-left : 0;
  margin-right: 20px;
}

@media screen and (min-width: 1000px) and (max-width:1100px) {
  .faq-question {
    width: 62%;
  }
}

@media screen and (max-width: 1000px) {
  .faq-question {
    background   : #FFFFFF;
    box-shadow   : 10px 10px 99px #D3D3D329;
    margin-bottom: 25px;
    border-radius: 14px;
    overflow     : hidden;
    transition   : max-height 0.3s ease-in-out;
    width        : 105%;
  }

  .faq-answer {
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  .faq-section {
    padding         : 3rem 2rem;
    background-color: #F4F5F8;
  }

  .faq-bottom h2 {
    color    : #2A2A2A;
    font-size: 1rem;
  }

  .faq-bottom p {
    margin   : 0;
    color    : #5E6A8B;
    font-size: 0.8rem;
  }

  .faq-question-title {
    font-size: 0.9rem;
  }

  .faq-answer {
    font-size: 0.8rem;
    width    : 79%;
  }

  .ar-arrow-icon {
    margin-left : 0;
    margin-right: 20px;
  }

}



/* Become A Partner  */

.form-container {
  display        : flex;
  justify-content: space-around;
  align-items    : flex-start;
  padding        : 3rem 6rem;
  white-space    : pre-line;
}


.form-form h2 {
  font-size: 2.15rem;
  color    : #2A2A2A;
  margin   : 0;
}

.form-form h3 {
  font-size : 2.11rem;
  color     : #0047BBCC;
  margin-top: 1rem;
}

.form-form .ar-heading3 {
  font-size : 2.11rem;
  color     : #0047BBCC;
  margin-top: 0rem;
}

.form-form p {
  font-size: 1.1rem;
  color    : #2A2A2A;
}

.form-image {
  flex      : 0;
  text-align: center;
}

.ar-form-image {
  flex       : 0;
  text-align : center;
  padding-top: 2.5rem;
}

.input-grid {
  display              : grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap             : 20px;
  column-gap           : 2.1875rem;
  padding-bottom       : 2rem;
}

.input-group {
  display       : flex;
  flex-direction: column;
}

label {
  font-size    : 0.93rem;
  margin-bottom: 0.5rem;
}

input {
  padding      : 15px;
  margin-top   : 5px;
  border       : 1px solid #AEBDCAAB;
  border-radius: 10px;
  background   : #F4F5F8 0% 0% no-repeat padding-box;
}

input::placeholder {
  color: #AEBDCA;
}

.submit-button {
  background     : #0047BBCC 0% 0% no-repeat padding-box;
  box-shadow     : 0px 3px 6px #00000029;
  border-radius  : 10px;
  color          : #fff;
  padding        : 14px 20px;
  border         : none;
  font-size      : 0.93rem;
  cursor         : pointer;
  transition     : background-color 0.3s ease-in-out;
  width          : 43%;
  display        : flex;
  justify-content: center;
  align-self     : center;
}

.input-group p {
  font-size : 0.73rem;
  text-align: center;
}

.submit-button:hover {
  background-color: #0056b3;
}

.input-group a {
  text-decoration: underline;
  color          : #007bff;
}

.form-image a {
  text-decoration: underline;
  color          : #007bff;
}

.input-group a:hover {
  text-decoration: underline;
}

.form-image .image {
  width         : 400px;
  height        : 450px;
  padding-bottom: 0.7rem;
}

.under-image {
  line-height: 1.5rem;
}

@media screen and (max-width:1190px) {
  .input-grid {
    display              : grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap             : 20px;
    column-gap           : 2.1875rem;
    padding-bottom       : 2rem;
  }

  .form-container {
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : center;
  }

  .form-image .image {
    display: none;
  }

  .form-container {
    padding: 3rem 2rem;
  }

  .form-form h2 {
    text-align: center;
  }

  .form-form h3 {
    text-align: center;
  }

  .form-form p {
    margin-bottom: 2rem;
  }

  .input-group p {
    margin-bottom: 0;
  }

  .ar-form-image {
    padding-top: 0;
  }

  input {
    border    : 1px solid #AEBDCAAB;
    background: #FCFCFC 0% 0% no-repeat padding-box;
  }

  .under-image {
    color: #5E6A8B;
  }
}

@media screen and (max-width: 650px) {
  .form-form h2 {
    font-size : 1.1rem;
    text-align: center;
  }

  .form-form h3 {
    font-size : 1.1rem;
    text-align: center;
  }

  .form-form p {
    font-size  : 0.9rem;
    line-height: 1.5rem;
  }

  label {
    font-size: 0.8rem;
  }

  .under-image p {
    font-size: 0.8rem;
  }

  .input-group p {
    font-size    : 0.8rem;
    margin-bottom: 0;
  }

  .submit-button {
    width: 55%;
  }

  .form-form .ar-heading3 {
    text-align: right;
    font-size : 1.1rem;
  }

  .form-form .ar-heading2 {
    text-align: right;
  }
}

@media screen and (min-width: 1380px) {
  .form-form {
    width: 50%;
  }
}

@media screen and (min-width: 1220px) {
  .ar-form-form {
    width: 50%;
  }
}


















/* Footer  */

.footer {
  background-color: #F4F5F8;
  color           : black;
  padding         : 3rem 6rem;
}

.footer .logo img {
  width: 5rem;
}

.footer p {
  font-size: 0.93rem;
}

.footer-content {
  display        : flex;
  justify-content: space-around;
  flex-wrap      : wrap;
  padding        : 20px;
}

.left-column,
.middle-column,
.right-column {
  display       : flex;
  flex-direction: column;
  text-align    : center;
}

.footer .logo {
  font-size  : 24px;
  font-weight: bold;
  text-align : start;
}

.footer .social-icons {
  margin-top: 10px;
  width     : 50%;
}

.office,
.contact,
get-app,
help-center {}

.footer-bottom {
  text-align : center;
  padding-top: 20px;

}

.footer p {
  color: #5E6A8B;
}

.footer h3 {
  margin-top: 0;
}

.ar-footer h3 {
  margin-top   : 0;
  margin-bottom: 0;
}

.icon-text {
  display    : flex;
  align-items: center;
  gap        : 10px;
}

.icon img {
  width : 0.9rem;
  height: 0.9rem;
}

.footer-bottom p {
  text-align   : center;
  margin-bottom: 0;
}

.footer-bottom {
  padding-top: 1rem;
}

.privacy {
  padding-top: 0.5rem;
}

.arabic-paragraph {
  text-align: right;
}

.english-paragraph {
  text-align: left;
}

.arabic-end-section {
  order: 1;
}

.ar-middle-column {
  order: 3;
}

.icon-text p {
  margin: 0.5rem;
}

.help-center p {
  margin: 0.5rem;
}

.wrapping-footer-mob {
  display        : flex;
  gap            : 2rem;
  width          : 50%;
  justify-content: space-between;
}

.get-app {
  margin-top: 0;
}

.ar-footer-bottom p {
  text-align     : center;
  margin-bottom  : 0;
  display        : flex;
  flex-direction : row-reverse;
  justify-content: center;
}

.ar-hani {
  display       : flex;
  flex-direction: row-reverse;
}

.footer .ar-left-column {
  width: 100%;
}

.footer .arsi {
  width: 100%;
}

@media (max-width: 1245px) {
  .footer-content {
    flex-direction: column;
  }

  .left-column,
  .middle-column,
  .right-column {
    text-align: center;
  }

  .left-column {
    margin-bottom: 30px;
  }

  .middle-column {}

  .right-column {
    margin-top: 20px;
  }

  .mobile-footer {
    display: flex;
  }

  .wrapping-footer-mob {
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
    justify-content: space-between;
    width          : 100%;
  }

  .get-app {
    margin-top: -20px;
  }

  .help-center {
    margin-top: -20px;
  }

  .footer-content .arsi {
    display        : flex;
    align-items    : center;
    width          : 96%;
    justify-content: flex-start;
    list-style     : none;
    padding        : 0;
    z-index        : 1;
  }

  .footer-content .arsocial-icons {
    padding-right: 0;
    width        : 0;
  }

  .ar-middle-column {
    order: 1;
  }

  .footer h3 {}

  .ar-footer h3 {
    margin-bottom: 0;
  }

  .footer .social-icons {
    width  : 15%;
    padding: 0;
  }
}

@media (max-width: 650px) {
  .footer-content h3 {
    font-size: 1rem;
  }

  .footer-content p {
    font-size: 0.9rem;
  }

  .footer {
    padding: 2rem 2rem;
  }

  .icon img {
    width : 0.7rem;
    height: 0.7rem;
  }

  .footer .social-icons {
    width: 15%;
  }

  .footer .social-icons {
    margin-top: 10px;
    width     : 50%;
  }
}



/* Terms */



.privacy-section {
  padding-left : 8rem;
  padding-right: 8rem;
}

.privacy-section li {
  list-style-type: none;
}

.article {
  padding-bottom: 3rem;
}

.article h2 {
  text-align    : center;
  color         : #335A79;
  font-size     : 2.1875rem;
  padding-bottom: 1.5rem;
  font-family   : "EngFontMedium";
}

.article p {
  line-height: 2.2rem;
  color      : #00121F;
  font-size  : 1.125rem;
  text-align : left;
  font-family: "EngFontMedium";
}

.article ul {
  line-height: 2.2rem;
  color      : #00121F;
  font-size  : 1.125rem;
  font-family: "EngFontMedium";
  padding    : 0;
}

.article ul li {

  cursor: auto;
}

.blue-p {
  color          : #6CC4DC !important;
  text-decoration: underline;
}

.article-ar {
  padding-bottom: 3rem;
}

.article-ar h2 {
  text-align    : center;
  color         : #335A79;
  font-size     : 2.1875rem;
  padding-bottom: 1.5rem;
  font-family   : "ArFontMedium";
}

.article-ar p {
  line-height: 2.2rem;
  color      : #00121F;
  font-size  : 1.125rem;
  text-align : right;
  font-family: "ArFontMedium";
}

.article-ar ul {
  line-height: 2.2rem;
  color      : #00121F;
  text-align : right;
  font-size  : 1.125rem;
  font-family: "ArFontMedium";
  padding    : 0;
}

.article-ar ul li {
  cursor: auto;
}


@media all and (max-width:940px) {
  .privacy-section {
    padding-left : 2rem;
    padding-right: 2rem;
  }

  .article {
    padding-bottom: 2rem;
  }

  .article h2 {
    text-align    : center;
    color         : #335A79;
    font-size     : 1.05rem;
    padding-bottom: 1rem;
    font-family   : "EngFontMedium";
  }

  .article p {
    line-height: 1.7rem;
    color      : #00121F;
    font-size  : 0.75rem;
    text-align : left;
    font-family: "EngFontMedium";
  }

  .article ul {
    line-height: 1.7rem;
    color      : #00121F;
    font-size  : 0.75rem;
    font-family: "EngFontMedium";
  }

  .article ul li {

    cursor: auto;
  }

  .blue-p {
    color          : #6CC4DC !important;
    text-decoration: underline;
  }

  .article-ar {
    padding-bottom: 3rem;
  }

  .article-ar h2 {
    text-align    : center;
    color         : #335A79;
    font-size     : 1.25rem;
    padding-bottom: 1rem;
    font-family   : "ArFontMedium";
  }

  .article-ar p {
    line-height: 1.7rem;
    color      : #00121F;
    font-size  : 0.75rem;
    text-align : right;
    font-family: "ArFontMedium";
  }

  .article-ar ul {
    line-height: 1.7rem;
    color      : #00121F;
    text-align : right;
    font-size  : 0.75rem;
    font-family: "ArFontMedium";
  }

  .article-ar ul li {

    cursor: auto;
  }
}